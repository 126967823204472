import DateCreationEditionMixin from "../../mixins/DateCreationEditionMixin.js";
import Model, {type} from "../../abstract/Model.js"

/**
 * @class
 * @extends AbstractModel
 * @augments DateCreationEditionMixin
 */
export default class CmsAnalyticsSearch extends DateCreationEditionMixin(Model) {
    static collection = `cms_analytics_searches`;

    static indexes = [{"item.id": 1}];

    dealerid = {
        [type]: String,
        flags: [F_LOWER],
        required: true,
        index: 1
    }

    websiteId = {
        [type]: String,
        required: true,
        index: 1
    }

    sessionId = {
        [type]: String,
        required: true,
        index: 1
    }

    search = {
        [type]: String,
        required: true
    }

    results = {
        [type]: Number,
        default: () => 0,
        required: true
    }

    item = {
        [type]: Object,
        default: () => {}
    }

    keywords = {
        [type]: Array
    }

    constructor() {
        super(...arguments);
    }

    /*
    To store a summary in each session
     */
    getSummaryFields() {
        return {
            dateCreation: this.dateCreation,
            search: this.search,
            results: this.results,
            item: this.item,
            keywords: this.keywords
        }
    }
}
